// import React, { useEffect, useState } from 'react';
import React from "react";
// import HomepageBanner from "@assets/videos/GPA_Launch_HomeBanner.mp4";
import { Images, Icons } from "@core";
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';
import { HomeLearnMoreItem } from "@src/components";

export const Home = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);

  // const getData = async () => {
  //   const fileDatas = await getFileData();
  //   setFile(fileDatas.data);
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <>
      {/* <div
        className="pt-4 items-center phone:justify-center phone:flex-col phone:px-8 tablet:px-0 tablet:block related mt-20 desktop:mt-28"
        style={{
          minHeight: "500px",
          backgroundImage: `url(${Images.GPABeattheBeakBannerv2})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          className="flex flex-col items-center pt-12"
          style={{
            maxWidth: "750px",
          }}
        >
          <img
            alt="logo"
            className="h-32 w-42 right-12"
            src={Images.BeatThePeakLogov2}
          />
          <h1
            className="font-newmake text-white text-center phone:text-3xl phone:mb-6 tablet:mt-6 tablet:text-6xl"
            style={{
              maxWidth: "750px",
            }}
          >
            YOU HAVE THE POWER TO STOP{" "}
            <span className="with-outline">OUTAGES</span>
          </h1>
          <a href="/beatthepeak" target="_blank">
            <button
              className="phone:text-lg text-2xl bg-green phone:w-full phoneWide:w-max phone:mx-auto px-4 rounded-full text-white font-bold py-2 block"
              style={{
                maxWidth: "300px",
                backgroundColor: "#DC3545",
              }}
              type="button"
            >
              BEAT THE PEAK
            </button>
          </a>
        </div>
      </div> */}
      {/* <div className="h-full tablet:max-h-400px relative mt-20 desktop:mt-28 ">
        <div className="absolute h-full bg-black/50" />
        <img
          alt="Mawar BG"
          className="z-1 h-full w-full object-cover absolute opacity-40 tablet:opacity-100"
          src={Images.HomePageAugust}
        />
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" />
        <section className="h-full w-full tablet:w-auto flex items-center laptop:px-40 tablet:px-36 phone:px-10 z-20 absolute">
          <div className="w-full flex flex-col py-20">
            <div className="relative flex ml-0 tablet:-ml-20 flex-col justify-center items-center">
              <img
                alt="GPA55thlogo2"
                className="w-16 h-16 mb-4 mr-6"
                src={Images.GPA55thlogo2}
              />
              <h2 className="font-jubilat font-bold text-blue mb-5 text-6xl tablet:text-4xl tablet:text-left text-center">
                Bringing <br />
                Energy <br />
                Solutions <br />
                to You
              </h2>
            </div>
          </div>
        </section>
      </div> */}
      <div
        className="h-96 relative mt-20 desktop:mt-28 hidden"
        style={{
          minHeight: "350px",
          backgroundImage: `url(${Images.GPAPotentialOutageBanner})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="flex phone:flex-col tabletWide:flex-row items-center">
          <img
            alt="Alert"
            className="h-24 w-24 object-cover phone:mt-16 tabletWide:mt-18 tabletWide:ml-20"
            src={Images.Alert}
          />
          <div className="flex flex-col tabletWide:items-start phone:items-center phoneLg:ml-0 phone:px-10 tabletWide:mt-20">
            <h1
              className="font-rubik font-normal text-white font-bold phone:text-lg tablet:text-6xl laptop:text-6xl 
  desktop:text-6xl mb-1 phone:mt-0 tablet:mt-5 phone:text-center tabletWide:text-left"
            >
              January 2024
            </h1>
            <h1
              className="font-rubik font-light phone:text-sm tablet:text-base laptop:text-4xl 
      desktop:text-4xl mb-5 text-white phone:text-center tabletWide:text-left phone:text-xs"
            >
              POTENTIAL ROTATING OUTAGE SCHEDULE
            </h1>
            <a
              href="/potential-rotating-outage-schedule"
              rel="noreferrer"
              target="_blank"
            >
              <button
                className="mt-3 py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                type="button"
              >
                More Info Here
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="h-full tablet:max-h-400px relative mt-20 desktop:mt-28 ">
        <div className="absolute h-full bg-black/50" />
        <img
          alt="Mawar BG"
          className="z-1 h-full w-full object-cover absolute opacity-40 tablet:opacity-100"
          src={Images.HomePageAugust}
        />
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" />
        <section className="h-full w-full tablet:w-auto flex items-center laptop:px-40 tablet:px-36 phone:px-10 z-20 absolute">
          <div className="w-full flex flex-col py-20">
            <div className="relative flex ml-0 tablet:-ml-20 flex-col justify-center items-center">
              <img
                alt="GPA55thlogo2"
                className="w-16 h-16 mb-4 mr-6"
                src={Images.GPA55thlogo2}
              />
              <h2 className="font-jubilat font-bold text-blue mb-5 text-6xl tablet:text-4xl tablet:text-left text-center">
                Bringing <br />
                Energy <br />
                Solutions <br />
                to You
              </h2>
            </div>
          </div>
        </section>
      </div>

      <section className="bg-lightgray pb-20 laptop:px-28 tablet:px-20 phone:px-10">
        <section className="w-full grid grid-cols-1 tablet:grid-cols-2 tabletWide:grid-cols-3 tablet:grid-rows-3 tabletWide:grid-rows-2 gap-6 tabletWide:gap-8 py-20">
          <HomeLearnMoreItem
            icon={Icons.OnlinePayment}
            link="https://paygpa.com/"
            title="Make an online payment"
          />
          <HomeLearnMoreItem
            icon={Icons.WattsNew}
            link="/news-and-media"
            title="See Watts New"
          />
          <HomeLearnMoreItem
            icon={Icons.EnergyTips}
            link="https://energysense.guampowerauthority.com/savings-tips"
            title="Ways to Save Energy"
          />
          <HomeLearnMoreItem
            icon={Icons.TransferService}
            link="/how-do-i/start-stop-move-services"
            title="Start, Stop, & Move Service"
          />
          <HomeLearnMoreItem
            icon={Icons.MyEnergyGuam}
            link="https://www.myenergyguam.com/HomeConnect/login.html"
            title="View My Energy Usage"
          />
          <HomeLearnMoreItem
            icon={Icons.ViewOuttages}
            link="https://web.guamalerts.com/notification/gpa"
            title="View Outages"
          />
        </section>

        <div
          className="carousel slide carousel-fade carousel-dark relative w-full carousel__height"
          data-bs-ride="carousel"
          id="carouselDarkVariant"
        >
          <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
            <button
              aria-current="true"
              aria-label="Slide 1"
              className="active carousel-buttons"
              data-bs-slide-to="0"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />

            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="1"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="2"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="3"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="4"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="5"
              data-bs-target="#carouselDarkVariant"
              type="button"
            />
            {/* <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="6"
              data-bs-target="#carouselDarkVariant"
              type="button"
            /> */}
            {/* <button
              aria-label="Slide 1"
              className="carousel-buttons"
              data-bs-slide-to="7"
              data-bs-target="#carouselDarkVariant"
              type="button"
            /> */}
          </div>

          <div className="carousel-inner relative w-full h-full overflow-hidden">

          {/* <div className="carousel-item active relative w-full h-full">
              <div className="absolute top-0 left-0 z-10 w-full laptop:p-14 phone:p-8">
                <p className="font-jubilat text-white text-5xl font-normal laptop:text-custom tablet:text-4xl phone:text-2xl mt-1">
                  Additional $100 power credit for You!
                </p>
                <p className="font-rubik font-normal text-white text-4xl mt-2 laptopSm:mt-5 tablet:text-3xl phone:text-xl">
                  <i>Prugråman Ayuda Para I Taotao-ta Energy Credit</i>
                </p>
                <p className="font-rubik font-normal text-white text-3xl mt-2 laptopSm:mt-5 tablet:text-2xl phone:text-base">
                  Public Law 37-49 extended additional credits of $100 per month
                  from October through December 2023 bringing financial relief 
                  to residential, master-meter residential, and commercial customers.
                </p>
                <div className="mt-5">
                  <a
                    href="https://admin.guampowerauthority.com/uploads/GPA_Power_Credit_FA_Qs112023_8c72ff2ce1.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <button
                      className="mt-3 py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                      type="button"
                    >
                      More Info
                    </button>
                  </a>
                </div>
              </div>
              <img
                alt="PowerButton"
                className="absolute top-0 left-0 z-0 w-full h-full object-fit object-center"
                src={Images.GPAMawarBG}
              />
            </div> */}

            <div className="carousel-item active relative w-full h-full">
              <div className="absolute top-0 left-0 z-10 h-full w-full laptop:p-8 phone:p-8 flex flex-col items-start justify-center">
                <div className="flex flex-col tabletWide:flex-row">
                  <div
                    className="flex items-center pt-12 justify-between"
                    style={{
                      maxWidth: "750px",
                    }}
                  >
                    <div className="phoneWide:ml-0 phoneLg:ml-8 tabletWide:ml-0">
                      <h1
                        className="font-newmake text-white text-center phone:text-3xl tablet:text-6xl tabletWide:mr-24"
                        style={{
                          maxWidth: "750px",
                        }}
                      >
                        YOU HAVE THE POWER TO STOP{" "}
                        <span className="with-outline">OUTAGES</span>
                      </h1>
                      <a href="/beatthepeak" target="_blank">
                        <button
                          className="phone:text-lg text-2xl bg-green phone:w-full phoneWide:w-max phone:mx-auto px-4 rounded-full text-white font-bold py-2 block mb-12"
                          style={{
                            maxWidth: "300px",
                            backgroundColor: "#DC3545",
                          }}
                          type="button"
                        >
                          BEAT THE PEAK
                        </button>
                      </a>
                    </div>
                  </div>
                  <img
                    alt="logo"
                    className="h-24 w-42 ml-48 tabletWide:mt-16 laptopSm:mt-0 laptopSm:mb-16 phone:hidden tabletWide:block"
                    src={Images.BeatThePeakLogov2}
                  />
                </div>
              </div>
              <img
                alt="PowerButton"
                className="absolute top-0 left-0 z-0 w-full h-full object-fit object-center phone:hidden tabletWide:block"
                src={Images.BeatThePeakCarousel}
              />
              <img
                alt="PowerButton"
                className="absolute top-0 left-0 z-0 w-full h-full object-fit object-center phone:block tabletWide:hidden"
                src={Images.BeatThePeakRed}
              />
            </div>
{/* 
            <div className="carousel-item  relative float-left w-full h-full">
              <div className="h-full w-full flex tabletWide:flex-row phone:flex-col">
                <div className="anniv-carousel w-auto tabletWide:w-3/5 tabletWide:bottom-14 bottom-0 w-full tabletWide:left-96 flex flex-col justify-center tablet:items-start items-center laptop:px-14 phone:px-8 absolute left-0 top-0 right-0 ">
                  <h2 className="font-jubilat font-bold text-white mb-5 tablet:text-5xl phone:text-4xl tablet:text-left text-center">
                    55 Years of Bringing Energy Solutions to You.
                  </h2>

                  <div className="mt-5">
                    <a
                      href="/corporate/about-us#about-us-general-manager"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <button
                        className="py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                        type="button"
                      >
                        Message from the General Manager
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  alt="PowerButton"
                  className="absolut top-0 bottom-0 left-0 right-0 overflow-hidden h-full object-fit object-center"
                  src={Images.FiveFiveYearsMayActiveCarousel}
                />
              </div>
            </div> */}

            <div className="carousel-item relative float-left w-full h-full">
              <div className="w-full h-8 csd-home__linear-gradient" />
              <div className="h-full w-full flex tabletWide:flex-row phone:flex-col bg-lightGreen">
                <div className="w-auto tabletWide:w-3/5 mr-auto flex flex-col justify-center laptop:p-14 phone:p-8">
                  <h2 className="font-jubilat text-blue laptop:text-5xl phone:text-2xl">
                    Customer Service Directory
                  </h2>
                  <p className="font-rubik font-normal text-white text-sm mt-2 laptopSm:mt-5 laptop:text-xl phone:text-lg">
                    As our valued customer, we offer tips to save money on your
                    power bill, tools to manage your account and resource
                    information for all things power! Get the power to save now!
                  </p>
                  <div className="mt-5">
                    <a
                      href="https://admin.guampowerauthority.com/uploads/GPA_Customer_Directory_Guam_Webz_FEB_2023_966494ae6a.pdf?updated_at=2023-02-08T06:16:58.309Z"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <button
                        className="py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                        type="button"
                      >
                        Click Here
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  alt="PowerButton"
                  className="w-full tabletWide:w-2/5 laptop:w-auto overflow-hidden object-contain object-bottom"
                  src={Images.ExpertElectrician}
                />
              </div>
            </div>

            <div className="carousel-item relative relative w-full h-full">
              <div className="absolute top-0 left-0 z-10 w-full laptop:p-14 phone:p-8">
                <p className="font-jubilat text-white text-6xl font-bold tracking-wide laptop:text-6xl tabletWide:text-5xl tablet:text-left phone:text-left tablet:text-5xl phone:text-2xl mt-12 w-1/2 tabletWide:text-center laptop:text-center phone:text-center phone:w-full tabletWide:w-1/2 laptop:w-1/2 phone:mt-40 tabletWide:mt-16 laptop:mt-12">
                  We are
                  <br />
                  #CommunityPowered
                </p>
                <div className="mt-5 w-1/2 text-center tablet:text-left phone:text-left tabletWide:text-center laptop:text-center phone:text-center phone:w-full tabletWide:w-1/2 laptop:w-1/2">
                  <a
                    href="https://gpa.guamunity.com/community-powered"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <button
                      className="mt-3 py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                      type="button"
                    >
                      Click Here
                    </button>
                  </a>
                </div>
              </div>
              <img
                alt="PowerButton"
                className="absolute top-0 left-0 z-0 w-full h-full object-cover object-top phone:object-left"
                src={Images.GpaCommunityPowered}
              />
            </div>

            <div className="carousel-item relative float-left w-full h-full bg-white">
              <div className="h-full w-full flex tabletWide:flex-row phone:flex-col">
                <div className="w-auto tabletWide:w-3/5 mr-auto flex flex-col justify-center laptop:p-14 phone:p-8">
                  <h2 className="font-jubilat text-blue laptop:text-5xl phone:text-2xl">
                    Get outage alerts
                    <br className=" hidden laptopSm:block " />
                    on your street
                  </h2>
                  <p className="font-rubik font-normal text-black text-sm mt-2 laptopSm:mt-5 laptop:text-xl phone:text-lg">
                    GPA Power Alerts is a 2-way text messaging service,
                    providing customers with real time information about any
                    scheduled or unscheduled power outage affecting their home
                    and/or business, and estimated time of restoration.
                  </p>
                  <div className="mt-5">
                    <a href="/power-alerts">
                      <button
                        className="py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                        type="button"
                      >
                        About Alerts
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  alt="PowerButton"
                  className="w-full tabletWide:w-2/5 laptop:w-auto overflow-hidden object-cover"
                  src={Images.PowerAlerts}
                />
              </div>
            </div>

            <div className="carousel-item relative float-left w-full h-full bg-blue">
              <div className="h-full w-full flex tabletWide:flex-row phone:flex-col px-8">
                <div className="w-auto tabletWide:w-1/2 mr-auto flex flex-col justify-center laptop:pl-6 laptop:pt-14 laptop:pr-14 laptop:pb-14 phone:px-0 phone:py-8">
                  <h2 className="font-rubik text-white laptop:text-4xl phone:text-2xl">
                    DOWNLOAD THE PAYGPWA MOBILE APP
                  </h2>
                  <p className="font-rubik font-bold text-white mt-5 laptop:text-4xl phone:text-lg">
                    Now with more features to easily pay, view and manage your
                    accounts
                  </p>
                  <div className="w-auto phoneWide:w-3/5 flex flex-row mt-5 gap-2">
                    <a href="https://apps.apple.com/us/app/pay-gpwa/id1593835535">
                      <img
                        alt="Download through Apple App Store"
                        src={Images.AppStore}
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.xtendly.paygpwa">
                      <img
                        alt="Download through Google Play Store"
                        src={Images.PlayStore}
                      />
                    </a>
                  </div>
                </div>
                <div className="w-full overflow-auto object-cover pt-0 tabletWide:pt-10 tabletWide:w-1/2 tabletWide:flex tabletWide:items-end laptopSm:w-2/5 laptop:w-2/5 desktopWide:w-2/6">
                  <img
                    alt="PayGPWA App"
                    className="block"
                    src={Images.PayGWA}
                  />
                </div>
                {/* <img alt="PayGPWA App" className="w-full tabletWide:w-1/2 laptop:w-auto overflow-auto object-cover pt-0 tabletWide:pt-10" src={Images.PayGWA} /> */}
              </div>
            </div>

            <div className="carousel-item relative float-left w-full h-full bg-white">
              <div className="h-full w-full flex tabletWide:flex-row phone:flex-col">
                <div className="w-auto mr-auto flex flex-col justify-center laptopSm:w-3/5 tabletWide:w-3/6 laptop:p-14 phone:p-8">
                  <h2 className="font-jubilat text-blue laptop:text-5xl phone:text-2xl">
                    Learn how GPA's Clean Energy Master Plan will bring Guam to
                    50% renewable energy by 2030 and 100% by 2045.
                  </h2>
                  {/* <p className="font-rubik font-normal text-black mt-2 laptop:text-xl phone:text-lg">
                      The Clean Energy Master Plan
                    </p> */}
                  <a
                    className="mt-5"
                    href="/gpa-initiatives/clean-energy-master-plan"
                  >
                    <button
                      className="font-rubik font-normal py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                      type="button"
                    >
                      Clean Energy Master Plan
                    </button>
                  </a>
                </div>
                <div className="w-full flex-1 overflow-hidden object-cover object-center tabletWide:w-3/6 tabletWide:flex tabletWide:items-center laptopSm:w-2/5">
                  <div className="youtube-video__container">
                    <iframe
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      className="youtube-video__iframe "
                      frameBorder="0"
                      height="720"
                      src="https://www.youtube.com/embed/TwlmVXmSk34"
                      title="GPA's Clean Energy Master Plan"
                      width="1280"
                    />
                  </div>
                </div>
                {/* <img
                    alt="GPA YouTube Channel"
                    className="w-full tabletWide:w-2/5 laptop:w-auto overflow-hidden object-cover object-center"
                    src={Images.WatchGPA}
                  /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center flex-col phone:pt-14 tabletWide:px-64 tablet:px-20">
          <h1
            className="font-jubilat text-center text-blue laptop:mb-14 phone:mb-10 laptop:text-5xl 
        tablet:text-5xl phone:text-4xl"
          >
            GPA will achieve a 25% renewable energy portfolio mix by 2024
          </h1>
          <p className="font-rubik font-normal text-center text-black mb-5 laptop:text-2xl tablet:text-2xl phone:text-lg">
            Learn about our <b>Clean Energy Master Plan</b> and how it benefits
            you and our island.
          </p>
          <a href="/gpa-initiatives">
            <button
              className="bg-gradient-to-r from-clean-green to-clean-lightyellow rounded-full text-white text-bold 
          laptop:px-14 laptop:py-4 phone:px-10 phone:py-3"
              type="button"
            >
              Learn about our vision
            </button>
          </a>
        </div>
      </section>
      <section className="flex items-center justify-center  tablet:px-20 phone:px-10 bg-white py-10">
        <div className="flex flex-col items-center justify-center w-full max-w-5xl">
          <div
            className="w-full p-8 flex items-center tablet:gap-10  tablet:flex-row phone:flex-col laptop:h-44 
        phone:h-auto"
          >
            <img
              alt="PowerButton"
              className="border-2 border-black rounded-full w-full max-w-150px"
              src={Images.Doa}
            />
            <p className="font-rubik font-normal text-black laptop:text-xl phone:lg tabletWide:mt-0 phone:mt-10 text-justify">
              Available now. Funding is available through the Department of
              Administration with the Emergency Rental Assistance (ERA) program,
              to assist households that are unable to pay rent and utility
              expense dating back to March 13, 2020. Eligible households may
              receive up to 15 months of assistance, to include current rent and
              utility payments, and arrears. Ratepayers are encouraged to visit
              www.doa.guam.gov for more information.
            </p>
          </div>
          <a className="mt-5" href="/how-do-i/get-financial-assistance">
            <button
              className="font-rubik font-normal py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
              type="button"
            >
              Read More
            </button>
          </a>
        </div>
      </section>
    </>
  );
};