import { HeaderGradient } from "@src/components";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React, { useEffect, useState } from "react";
import { getMilestoneData } from "@src/services/getMilestoneData";
import  DOMPurify from 'dompurify';

const Milestone = () => {
  const [milestones, setMilestones] = useState<any[]>([])
  const [years, setYears] = useState<string[]>([])

  const handleChangeYear = (year:string) => {
    const element = document.getElementById(year)
    if(element){
      element.scrollIntoView({behavior:"smooth"})
    }
  }

  const fetchMilestoneData = async() => {
    const milestoneData = await getMilestoneData()

    if(milestoneData){
      
      const milestoneArray = Object.values(milestoneData.data);
      const yearArray:string[] = []
      let currentYear = ""

      milestoneArray.forEach((item:any) => {
          if (item.attributes && item.attributes.Date === null) {
              item.attributes.Date = item.attributes.createdAt;  
          }
          item.attributes.DateObject = new Date(item.attributes.Date)
      });

      milestoneArray.sort((a:any, b:any) => b.attributes.DateObject - a.attributes.DateObject);

      milestoneArray.forEach((item:any) => {
        if(item.attributes){
          const separatorIndex = item.attributes.Date.indexOf("-")
          const itemYear = item.attributes.Date.substr(0, separatorIndex);
          item.attributes.year = itemYear
          if(currentYear !== itemYear){
            yearArray.push(itemYear)
            currentYear = itemYear
            item.attributes.isStart = true
          }else{
            item.attributes.isStart = false
          }
        }
        if(item.attributes.File.data !== null){
          console.log(item.attributes.File.data[0].attributes.url)
          console.log(item.attributes.Anchor)
          const anchor = item.attributes.Anchor
          console.log(anchor.includes(item.attributes.File.data[0].attributes.url))
        }
      });

      console.log(yearArray)

      console.log(milestoneArray);
      setYears(yearArray)
      setMilestones(milestoneArray)
    }
  }

  useEffect(() => {
    fetchMilestoneData()
    setMilestones([])
    console.log(milestones)
  }, [])

  return <>
  <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="GPA Milestones"
      />
    <div className="mt-6 mr-10 flex flex-row justify-end cursor-pointer">
      <select onChange={(e) => {handleChangeYear(e.target.value)}}>
        {years.map((item, index) => (
           <option key={index} value={item}>{item}</option>
        ))}
      </select>
    </div>
    <div className="mt-6 flex flex-col items-center">
      <div className="font-jubilat font-bold tracking-normal text-center text-blue laptop:text-3xl tablet:text-2xl phone:text-xl mx-3">GPA Milestones, achievements in the last</div>
      <div className="my-3 font-jubilat font-bold tracking-normal text-center text-blue laptop:text-6xl tablet:text-4xl phone:text-3xl mx-3">20 years</div>

      <div className="flex flex-row flex-wrap items-center my-16 justify-center">
        <div className="flex flex-col text-center items-center justify-center w-1/2 min-w-432px">
          <div className="font-jubilat font-bold tracking-normal text-center text-blue laptop:text-3xl tablet:text-2xl phone:text-xl mx-3">
            Goal to reach
          </div>
          <div className="font-jubilat font-bold tracking-normal text-center text-blue laptop:text-3xl tablet:text-2xl phone:text-xl mx-3">
            (2040)
          </div>
        </div>
        <div className="flex flex-row items-center w-1/2 min-w-210px">
          <img alt="renewable_img" src="https://admin.guampowerauthority.com/uploads/100_renewable_Power_9f0e157fe9.png" width="100%"/>
        </div>
      </div>
      
      <VerticalTimeline lineColor="#C1C1C1">
      {
        milestones.map((milestone, index) => (
          <VerticalTimelineElement 
            className="vertical-timeline-element--work"
            contentArrowStyle={milestone.attributes.isStart?{ borderRight: '7px solid #00509D'} : { borderRight: '0px solid  black' }}
            contentStyle={ milestone.attributes.isStart? { background: 'white',  border:"solid 2px #00509D", color: '#fff'}: {border:"solid 1px #E0E0E3"}}
            date={milestone.attributes.isStart? milestone.attributes.year : ""}
            iconStyle={milestone.attributes.isStart ? { background: '#00509D' } : {display:'none'} }
            id={milestone.attributes.isStart? milestone.attributes.year : ""}
            key={index}
          >
            <h3 className="vertical-timeline-element-title text-blue text-xl">{milestone.attributes.Title}</h3>
            <div className="text-black font-light text-sm my-5">{milestone.attributes.Description}</div>

            {milestone.attributes.Anchor ? <div className="text-black font-light text-sm my-5" dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(milestone.attributes.Anchor)}} />:<></>}

            {milestone.attributes.File.data !== null && (milestone.attributes.File.data[0].attributes.ext === ".jpg" || milestone.attributes.File.data[0].attributes.ext === ".png") && (!milestone.attributes.Anchor.includes(milestone.attributes.File.data[0].attributes.url)) 
              ? <img alt="Test" src={`https://guampowerauthority-api.xtendly.com${milestone.attributes.File.data[0].attributes.url}`} />
              : <></>}

            {milestone.attributes.File.data !== null && (milestone.attributes.File.data[0].attributes.ext === ".pdf") && (!milestone.attributes.Anchor.includes(milestone.attributes.File.data[0].attributes.url))
              ? <a className="text-blue underline" href={`https://guampowerauthority-api.xtendly.com${milestone.attributes.File.data[0].attributes.url}`} rel="noopener noreferrer"  target="_blank" >{milestone.attributes.File.data[0].attributes.name}</a>
              : <></>}

          </VerticalTimelineElement>
        ))
      }
    </VerticalTimeline>
      
      <div className="my-10" />
    </div>
</>
};

export default Milestone;
